import React from "react";
import { Image, Menu, Icon,  Popup } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../hooks";
import { ENV } from "../../../utils";
import { image } from "../../../assets";
import "./UserMenu.scss";

export function UserMenu() {
  const { pathname } = useLocation();
  const {
    user: {  avatar },
  } = useAuth();

  const Iavatar = avatar;

  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (
    <Menu fluid vertical icon text className="admin-menu">
        <>
            <div className="box" align='center' >
            <Image src={Iavatar ? `${ENV.BASE_PATH}/${Iavatar}` : image.noAvatar}/>
            </div>
            <Menu.Item
            as={Link}
            to="/user/info"
            active={isCurrentPath("/user/enlaces")}
          >
            <Popup content='Mi información' trigger={<Icon name="user outline"/>} />
            <div className="quitar" >  Mi información </div>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/user"
            active={isCurrentPath("/user")}
          >
            <Popup content='Enlaces' trigger={<Icon name="linkify" />} />
            <div className="quitar">Enlaces</div>
          </Menu.Item>
        </>

    </Menu>
  );
}
