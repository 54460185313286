import * as Yup from "yup";

export function initialValues(enlaces) {
  return {
    titulo: enlaces?.titulo || "",
    miniatura: enlaces?.miniatura || "",
    file: null,
    descripcion: enlaces?.descripcion || "",
    url: enlaces?.url || "",
    //precio: enlaces?.precio || undefined,
    id_rol: enlaces?.id_rol || undefined,
  };
}

export function validationSchema() {
  return Yup.object({
    titulo: Yup.string().required(true),
    miniatura: Yup.string().required(true),
    descripcion: Yup.string().required(true),
    url: Yup.string().required(true),
    //precio: Yup.number().required(true),
    id_rol: Yup.string().required(true),
  });
}
