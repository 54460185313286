export const itemsData = [
    {
      icon: "compass",
      title: "MISION",
      description:
        "Facilitar el acceso a información financiera y económica, precisa y oportuna, empoderando a empresas, inversores y tomadores de decisiones para que alcancen sus objetivos con confianza y seguridad.",
    },
    {
      icon: "eye",
      title: "VISION",
      description:
        "Nuestro visión es convertirnos en la empresa líder en generación, recopilación, almacenamiento y análisis de datos de relevancia coyuntural, para desarrollar una plataforma que consolide toda la información. ",
    },
    {
      icon: "handshake",
      title: "VALORES",
      description:
        "Calidad, Honestidad, Pasión, Responsabilidad, Solución, Competitividad",
    },

    {
      icon: "check circle",
      title: "SERVICIOS",
      description:
        "Consolidamos y analizamos datos sobre el mercado financiero y la economía ecuatoriana con un amplio nivel de desagregación.",
    },

  ];