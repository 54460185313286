import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { UserForm, ListUsers  } from "../../../components/User";
import { BasicModal } from "../../../components/Shared";
import "./Users.scss";

export function UserInfo() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const titleModal = (` Desea Actualizar sus datos?`);


  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      render: () => (
        <Tab.Pane attached={false}>
          <ListUsers usersActive={true} reload={reload} onReload={onReload}/>
        </Tab.Pane>
      ),
    }
  ];


  return (
    <>
      <div className="usersinfo-page">
        <Tab menu={{ secondary: true }} panes={panes} />
      </div>

        <BasicModal 
        show={showModal} 
        close={onOpenCloseModal} 
        title={titleModal}>

        <UserForm close={onOpenCloseModal} 
        onReload={onReload} />
        </BasicModal>
    </>
  );
}