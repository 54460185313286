import * as Yup from "yup";

export function initialValues(user) {
  return {
    avatar: user?.avatar || "",
    fileAvatar: null,
    nombre: user?.id_persona.nombre || "",
    apellido: user?.id_persona.apellido || "",
    telefono: user?.id_persona.telefono || "",
    email: user?.email || "",
    id_persona: user?.id_persona._id || "",
    id_rol: user?.id_rol || "",
    password: "",
  };
}

export function validationSchema(user) {
  return Yup.object({
    nombre: Yup.string().required(true),
    apellido: Yup.string().required(true),
    telefono: Yup.string().required("Campo obligatorio"),
    email: Yup.string().email(true).required(true),
    id_rol: Yup.string().required(true),
    password: user ? Yup.string() : Yup.string().required(true),
  });
}