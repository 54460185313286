import React from "react";
import { Container, Icon } from "semantic-ui-react";
import { map } from "lodash";
import { itemsData } from "./informacion.data";
import "./Informacion.scss";

export function Informacion() {
  return (
    <div className="info1">
    <Container>
      <h2>Despeja todas tus dudas</h2>
      <div className="info1__itemsI">
        {map(itemsData, (item, index) => (
          <div className="divicion1" key={index}>
            <div className="divicion2">
              <Icon name={item.icon} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
    </div>

  );
}
