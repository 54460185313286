import React, { useState, useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import Logotipo from '../../../assets/png/LogoWeb.png';
import { Menu } from "../../../api";
import DocuPDF from "../../../assets/doc/FINANDAX.pdf"
import "./TopBar.scss";

const menuController = new Menu();

export function TopBar() {
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await menuController.getMenu(true);
        setMenu(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []); 
//falta pdf a descargar del boton
  return (
    <header>
    <div className="top-bar">
      <Container>
        <div className="top-bar__left">
          <Link to="/" className="logo">
          <img src={Logotipo} className="logo" alt="Finandax" />
          </Link>

          <div className="menu">
            {map(menu, (item) => (
              <a key={item._id} href={item.url}>
                {item.titulo}
              </a>
            ))}
          </div>
        </div>
      
        <div className="top-bar__moreTop">
            <Button variant="info" primary  href={DocuPDF} target="_blank" rel="noopener noreferrer" download="PresentacionFinandax.pdf">
            Presentacion
            </Button>
        </div>
      </Container>
    </div>
    </header>
  );
}