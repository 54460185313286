import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { RegisterForm, LoginForm} from "../../../components/Admin/Auth";
import Logo from '../../../assets/png/LogoUser.png';
import "./Auth.scss";

export function Auth() {

  const [activeIndex, setActiveIndex] = useState(0);
  const openLogin = () => setActiveIndex(0);

  const panes = [
    {
      menuItem: "Iniciar Sesión",
      render: () => (
        <Tab.Pane>
          <LoginForm/>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Registro",
      render: () => (
        <Tab.Pane>
          <RegisterForm openLogin={openLogin} />
        </Tab.Pane>
      ),
    },
  ];

  return (
<div className="auth">
      <img src={Logo} className="logo" alt="Finandax" />
      <Tab
        panes={panes}
        className="auth__forms"
        activeIndex={activeIndex}
        onTabChange={(_, data) => setActiveIndex(data.activeIndex)}
      />
</div>
  );
}
