import React, { useState } from "react";
import { Image, Button, Container } from "semantic-ui-react";
import { image } from "../../../../assets";
import { BasicModal } from "../../../Shared";
import { ENV } from "../../../../utils";
import { UserForm } from "../UserForm";
import "./UserItem.scss";



export function UserItem(props) {
  const { user, onReload } = props;

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);

  const openUpdateUser = () => {
    setTitleModal(`Actualizar ${user.id_persona.nombre} ${user.id_persona.apellido}`);
    onOpenCloseModal();
  };

  return (
    <Container className="infoUser">
      <div className="infoUser__itemsUser">
          <div className="divicionU1">
            <Image className="Image"
            src={
              user.avatar ? `${ENV.BASE_PATH}/${user.avatar}` : image.noAvatar
            }
            />
          </div>

          <div className="divicionU2">
            <h1> Información </h1>
            <h2> Nombre: {user.id_persona.nombre}  </h2>            
            <h2> Apellido: {user.id_persona.apellido} </h2>
            <h2> Teléfono: {user.id_persona.telefono} </h2>
            <h2> Email: {user.email} </h2>
            {user.id_rol === 'user' ? (<h2> Suscripción: Activa</h2>) : (<h2>Suscripción: Inactiva</h2>)}
            <Button  icon primary onClick={openUpdateUser}>
            <h2> Actualizar </h2>
            </Button>
          </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <UserForm close={onOpenCloseModal} onReload={onReload} user={user} />
      </BasicModal>
    </Container>
  );
}
