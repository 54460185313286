import React from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./Menu.scss";

export function Menu() {
  return (
    <div className="footer-menu">
      <div className="Footer_Titulo">
        <div>
        <h4>Sitios Oficiales</h4>
        </div>
      

      </div>
      

      <Grid columns={2}>
        <Grid.Column>
          <Link to="https://www.seps.gob.ec/" target="_blank">
            <Icon name="book" /> SEPS
          </Link>
          <Link to="https://www.superbancos.gob.ec/bancos/" target="_blank">
            <Icon name="book" /> SUPERINTENDENCIA DE BANCOS
          </Link>
        </Grid.Column>

        <Grid.Column>
          <Link to="https://www.bce.fin.ec/" target="_blank">
            <Icon name="book" /> BANCO CENTRAL DEL ECUADOR
          </Link>
          <Link to="https://www.ecuadorencifras.gob.ec/institucional/home/" target="_blank">
            <Icon name="book" /> INEC
          </Link>
        </Grid.Column>
      </Grid>
    </div>

  );
}