import React from "react";
import { Button } from "semantic-ui-react";
import { map } from "lodash";
//import Logotipo from '../../../../assets/png/LogoWeb.png';
import { socialData } from "../../../../utils";
import "./Info.scss";

export function Info() {
  return (
    <div className="footer-infoF">
      <p>
       !! Siguenos !!
      </p>

      {map(socialData, (social) => (
        <Button
          key={social.type}
          as="a"
          target="_blank"
          href={social.link}
          color={social.type}
          icon={social.type}
        />
      ))}
    </div>
  );
}