import React from "react";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks";

export function Logout() {
  const { logout, user: { id_rol } } = useAuth();
  const isAdmin = id_rol === "admin";
  const navigate = useNavigate();

  const onLogout = () => {
    if(isAdmin){
    logout();
    navigate("/admin");
    }else{
    logout();
    navigate("/user");
    }
    
  };

  return (
    <Button icon basic color="red" onClick={onLogout}>
      <Icon name="power off" /> Cerrar sesión
    </Button>
  );
}