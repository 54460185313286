import { ENV } from "../utils";

export class Enlaces {
  baseApi = ENV.BASE_API;

  async getEnlaces(demo = undefined) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.ENLACES}?id_rol=${demo}`;
      const response = await fetch(url);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      console.log("Error controlado get enlace: ")
      throw error;
    }
  }

  async createEnlaces(accessToken, data) {
    try {
      
      const formData = new FormData();
      if(data.miniatura) formData.append("miniatura",data.miniatura);
      if(data.file) formData.append("miniatura", data.file);
      if(data.titulo)  formData.append("titulo", data.titulo);
      if(data.descripcion)  formData.append("descripcion", data.descripcion);
      if(data.url)  formData.append("url", data.url);
      if(data.id_rol)  formData.append("id_rol", data.id_rol);

      const url = `${this.baseApi}/${ENV.API_ROUTES.ENLACES}`;
      const params = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };
      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      console.log("Error Enlace controlado: ");
      console.log(error);
      throw error;
    }
  }

  async updateEnlaces(accessToken, idUser, userData) {
    try {
      const data = userData;
      const formData = new FormData();
      if(data.miniatura) formData.append("miniatura",data.miniatura);
      if(data.file) formData.append("miniatura", data.file);
      if(data.titulo)  formData.append("titulo", data.titulo);
      if(data.descripcion)  formData.append("descripcion", data.descripcion);
      if(data.url)  formData.append("url", data.url);
      if(data.id_rol)  formData.append("id_rol", data.id_rol);

      const url = `${this.baseApi}/${ENV.API_ROUTES.ENLACES}/${idUser}`;
      const params = {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      };

      const response = await fetch(url, params);
      const result = await response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      console.log("Error Enlace uptade controlado: ");
      console.log(error);
      throw error;
    }
  }

  async deleteEnlaces(accessToken, idEnlace) {
    try {
      const url = `${this.baseApi}/${ENV.API_ROUTES.ENLACES}/${idEnlace}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await fetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      console.log("Error Enlace delete controlado: ");
      console.log(error);
      throw error;
    }
  }
}
