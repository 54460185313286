import React from "react";
import { Routes, Route } from "react-router-dom";
import { UserLayput } from "../layouts";
import { UserBienvenido, UserInfo } from "../pages/User";
import { Auth } from "../pages/admin";
import { useAuth } from "../hooks";


export function UserRouter() {
  const { user } = useAuth();

  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  return (
    <Routes>
      {!user ? (
     <Route path="/user/*" element={<Auth/>} />
      ):(
          <>
          {["/user/"].map((path) => (
            <Route 
            key = {path} 
            path={path} 
            element={loadLayout(UserLayput, UserBienvenido)} 
            />
          ))}
  
          <Route path="/user/info" element={loadLayout(UserLayput, UserInfo)} />
          <Route path="/user/*" element={loadLayout(UserLayput, UserBienvenido)}/>
          </>
      )}
    </Routes>
  );
}