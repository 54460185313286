import React from "react";
import { AdminMenu, Logout } from "../../components/Admin/AdminLayout";
import Logotipo from '../../assets/png/LogoUser.png';
import Logotipo2 from '../../assets/png/finandax.png';
import "./AdminLayout.scss";

export function AdminLayout(props) {
  const { children } = props;

  return (
    <div className="admin-layout">
      <div className="admin-layout__left">
      <img src={Logotipo} className="logo" alt="Finandax" />
      <img src={Logotipo2} className="logoA2" alt="Finandax" />
      <AdminMenu />
      </div>
      <div className="admin-layout__right">
        <div className="admin-layout__right-header">
        <Logout />
        </div>
        <div className="admin-layout__right-content">{children}</div>
      </div>
    </div>
  );
}