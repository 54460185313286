import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { Informacion, EnlacesForm  } from "../../../components/User";
import { useAuth } from "../../../hooks";
import "./UserBienvenido.scss";

export function UserBienvenido() {
  const [reload, setReload] = useState(false);
  const { user : {id_rol} } = useAuth();
  const onReload = () => setReload((prevState) => !prevState);


  const panes = [
    {
      render: () => (
        <Tab.Pane >
          <EnlacesForm reload={reload} onReload={onReload}  />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
    {id_rol === 'user' ? (
      <div className="enlacesuser-page">
        <Tab panes={panes} />
      </div>
    ):(
      <div>
      <Informacion />
      </div>
    )
    }  
    </>
  );
}