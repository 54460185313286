import React from "react";
import { Menu, Icon,  Popup } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../../hooks";
import "../../../User/UserMenu/UserMenu.scss";

export function AdminMenu() {
  const { pathname } = useLocation();
  const {
    user: { id_rol },
  } = useAuth();
  const isAdmin = id_rol === "admin";
  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (
    <Menu fluid vertical icon text className="admin-menu">
      {isAdmin && (
        <>
          <Menu.Item
            as={Link}
            to="/admin/users"
            active={isCurrentPath("/admin/users")}
          >
            <Popup content='Usuarios' trigger={<Icon name="user outline" />} />
            <div className="quitar" >  Usuarios </div>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/admin/menu"
            active={isCurrentPath("/admin/menu")}
          >
             <Popup content='Menu' trigger={<Icon name="bars" />} />
            <div className="quitar" >  Menu </div>
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/admin/enlaces"
            active={isCurrentPath("/admin/enlaces")}
          >
             <Popup content='Enlaces' trigger={<Icon name="linkify" />} />
            <div className="quitar" >  Enlaces </div>
          </Menu.Item>

        </>
      )}

    </Menu>
  );
}
