const SERVER_IP = "finanserver.azurewebsites.net";

export const ENV = {
  BASE_PATH: `https://${SERVER_IP}`,
  BASE_API: `https://${SERVER_IP}/api/v1`,
  API_ROUTES: {
    REGISTER: "auth/register",
    LOGIN: "auth/login",
    REFRESH_ACCESS_TOKEN: "auth/refreshtoken",
    USER_ME: "user/me",
    USER_MEUSER: "user/meuser",
    USER: "user",
    USERS: "users",
    ROL: "rol",
    USERP: "userp",
    MENU: "menu",
    ENLACES: "enlaces",
  },
  JWT: {
    ACCESS: "access",
    REFRESH: "refresh",
  },
};