import * as Yup from "yup";

export function initialValues(menu) {
  return {
    titulo: menu?.titulo || "",
    url: menu?.url || "",
    protocol: "https://",
    estado: menu?.estado || true,
    orden: menu?.orden || undefined,
  };
}

export function validationSchema() {
  return Yup.object({
    titulo: Yup.string().required(true),
    url: Yup.string().required(true),
    orden: Yup.number().required(true),
  });
}