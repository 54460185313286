import React from "react";
import { Container } from "semantic-ui-react";
import "./Banner.scss";

export function Banner() {
  return (
    <div className="banner11">
      <Container>
        <h1>
          Finandax un vistazo <br /> al mercado financiero
        </h1>
        <h2>
          Descubre la mejor información del mercado
          <br />
          a través de la tecnologia Power BI.
        </h2>
      </Container>

      <div className="banner__dark1" />
    </div>
  );
}
