import React, { useState } from "react";
import { Image, Button, Icon, Confirm } from "semantic-ui-react";
import { BasicModal } from "../../../Shared";
import { ENV } from "../../../../utils";
import { Enlaces } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { EnlacesForm } from "../EnlacesForm";
import "./EnlacesItem.scss";

const enlaceController = new Enlaces();

export function EnlacesItem(props) {
  const { enlaces, onReload } = props;
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const { accessToken } = useAuth();

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateEnlaces = () => {
    setTitleModal(`Actualizar ${enlaces.titulo}`);
    onOpenCloseModal();
  };

  const onDelete = async () => {
    try {
      await enlaceController.deleteEnlaces(accessToken, enlaces._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="enlace-item">
        <div className="enlace-item__info">
          <Image src={`${ENV.BASE_PATH}/${enlaces.miniatura}`} />
          <div>
            <p>{enlaces.titulo}</p>
            <span className="enlace-item__info-path"> rol: {enlaces.id_rol}</span>
          </div>
        </div>

        <div>
          <Button icon as="a" href={enlaces.url} target="_blank">
            <Icon name="eye" />
          </Button>
          <Button icon primary onClick={openUpdateEnlaces}>
            <Icon name="pencil" />
          </Button>
          <Button icon color="red" onClick={onOpenCloseConfirm}>
            <Icon name="trash" />
          </Button>
        </div>
      </div>
      <div></div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <EnlacesForm
          onClose={onOpenCloseModal}
          onReload={onReload}
          enlaces={enlaces}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={`Eliminar enlace ${enlaces.titulo}`}
        size="mini"
      />
    </>
  );
}