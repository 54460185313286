import React, { useState, useEffect } from "react";
import { Container, Image } from "semantic-ui-react";
import { map } from "lodash";
import { Enlaces } from "../../../../api";
import { ENV } from "../../../../utils";
import "./EnlacesForm.scss";

const enlacesController = new Enlaces();

export function EnlacesForm() {
  const [enlace, setEnlace] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await enlacesController.getEnlaces("user");
        setEnlace(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Container className="infoEnlaceE">
      <div className="infoEnlaceE__itemsEnlaceE">
        {map(enlace, (enlaceuser) => (
          <div className="divicion3" key={enlaceuser._id} >
            <div className="divicion4">
            <Image src={`${ENV.BASE_PATH}/${enlaceuser.miniatura}`} href={enlaceuser.url} target="_blank" />
              <h3 >{enlaceuser.titulo}</h3>
              <p>{enlaceuser.descripcion}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
