import React from "react";
import { Container } from "semantic-ui-react";
import { TopBar, Footer } from "../../components/WEB";
import "./ClientLayout.scss";

export function ClientLayout(props) {
  const { children } = props;

  return (
    <div className="client-layout">
      <div className="client-layout__header">
        <TopBar />
      </div>
      {children}
      <div className="client-layout__footer">
        <Container>
              <div>
              <Footer.Info />
              <Footer.Menu />
              </div>
              
        </Container>
        <Container className="texto">
        <h12>Dere​chos Reservados 2023</h12>
        </Container>
        
      </div>
      
    </div>
  );
}