import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Auth } from "../../../../api";
import { initialValues, validationSchema } from "./RegisterForm.form";
import DocuPDF from "../../../../assets/doc/TERMINOS Y CONDICIONES.pdf"
import "./RegisterForm.scss";

const authController = new Auth();

export function RegisterForm(props) {
  const { openLogin } = props;
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        setError("");
        await authController.register(formValue);
        openLogin();
      } catch (error) {
        setError(error.message);
      }
    },
  });
  
  return (
<Form className="register-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        className="campos"
        name="nombre"
        placeholder="Nombre"
        onChange={formik.handleChange}
        value={formik.values.nombre}
        error={formik.errors.nombre}
      />

      <Form.Input
        className="campos"
        name="apellido"
        placeholder="Apellido"
        onChange={formik.handleChange}
        value={formik.values.apellido}
        error={formik.errors.apellido}
      />

      <Form.Input
        className="campos"
        name="telefono"
        placeholder="Teléfono"
        onChange={formik.handleChange}
        value={formik.values.telefono}
        error={formik.errors.telefono}
        type="number"
      />

      <Form.Input
        className="campos"
        name="email"
        placeholder="Correo electronico"
        onChange={formik.handleChange}
        value={formik.values.email}
        error={formik.errors.email}
      />
      
      <Form.Input
        className="campos"
        name="password"
        type="password"
        placeholder="Contraseña"
        onChange={formik.handleChange}
        value={formik.values.password}
        error={formik.errors.password}
      />

      <Form.Input
        className="campos"
        name="repeatPassword"
        type="password"
        placeholder="Repetir contraseña"
        onChange={formik.handleChange}
        value={formik.values.repeatPassword}
        error={formik.errors.repeatPassword}
      />


      <div >
            <a  href={DocuPDF} target="_blank" rel="noopener noreferrer" download="Politicas de privacidad.pdf">
            Políticas de privacidad
            </a>
        </div>


      <Form.Checkbox className="terminos"
        name="conditionsAccepted"
        label="He leído y acepto las políticas de privacidad"
        onChange={(_, data) =>
          formik.setFieldValue("conditionsAccepted", data.checked)
        }
        checked={formik.values.conditionsAccepted}
        error={formik.errors.conditionsAccepted}
      /> 



      <Form.Button type="submit" primary fluid loading={formik.isSubmitting} >
        <h5>Registrarse</h5>
      </Form.Button>
      <p className="register-form__error">{error}</p>
    </Form>
  )
}
