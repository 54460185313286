import React from "react";
import { Banner, Demo, Informacion,} from "../../../components/WEB";
import "./home.scss"

export function Home() {
  return (
    <div className="homes">
      <Banner />
      <Demo/>
      <Informacion/>
    </div>
  );
}
