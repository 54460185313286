import React, { useState, useEffect } from "react";
import { Container, Image, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Enlaces } from "../../../api";
import { ENV } from "../../../utils";
import "./Demo.scss";

const enlacesController = new Enlaces();

export function Demo() {
  const [enlace, setEnlace] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await enlacesController.getEnlaces("demo");
        setEnlace(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Container className="demo">
      <h2>Demostración Gratuita</h2>
      <h3>Descubre lo que podemos hacer por ti</h3>

      <div className="demo__all-courses">
        {map(enlace, (enlacedemo) => (
          // eslint-disable-next-line
          <a key={enlacedemo._id} href={enlacedemo.url} target="_blank">
            <Image src={`${ENV.BASE_PATH}/${enlacedemo.miniatura}`} />
            
            <div>
              <span>{enlacedemo.titulo}</span>
              <span>{enlacedemo.descripcion}</span>
            </div>
          </a>
        ))}
      </div>

      <div className="demo__more">
        <Button as={Link} to="/user" primary>
          ! Suscríbete !
        </Button>
      </div>
    </Container>
  );
}
