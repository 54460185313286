import React from "react";
import { Container, Icon } from "semantic-ui-react";
import { map } from "lodash";
import { itemsData } from "./informacion.data";
import { Link } from "react-router-dom";
import "./Informacion.scss";

export function Informacion() {
  return (
    <Container className="infoUU">
      <h2>!GRACIAS POR REGISTRATE!</h2>
      <h4>
        Contrata nuestra version premium
      </h4>
      <div className="infoUU__itemsUU">
      <div></div>
        {map(itemsData, (item, index) => (
          <div key={index}>
            <div>
              {item.title === "Contactanos" ? (
              <div>
          <Link to="https://wa.me/message/YXIHWBRQSYR3D1" target="_blank">
          <Icon name={item.icon} />
          </Link>
              
              <h3>{item.title}</h3>
                 <p>{item.description}</p>
              </div>
              ) : ( 
                <div>
              <Icon name={item.icon} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              </div>) }
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
