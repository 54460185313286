import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { size, map } from "lodash";
import { Enlaces } from "../../../../api";
import { EnlacesItem } from "../EnlacesItem";
import "./ListEnlaces.scss";

const enlacesController = new Enlaces();

export function ListEnlaces(props) {
  const { reload, onReload } = props;
  const [enlaces, setEnlaces] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await enlacesController.getEnlaces();
        setEnlaces(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [reload]);

  if (!enlaces) return <Loader active inline="centered" />;
  if (size(enlaces) === 0) return "No hay ningun enlace";

  return (
    <div className="list-enlaces">
      {map(enlaces, (enlace) => (
        <EnlacesItem key={enlace._id} enlaces={enlace} onReload={onReload} />
      ))}
    </div>
  );
}