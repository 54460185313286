import React from 'react'
import { HashRouter as Router } from "react-router-dom";
import { WebRouter, AdminRouter, UserRouter } from "./router";
import {AuthProvider} from "./contexts"

export default function App() {
  return (
  <AuthProvider>
    <Router>
        <WebRouter />
        <AdminRouter />
        <UserRouter />
    </Router>
  </AuthProvider>
  );
}
