import React from "react";
import { Logout } from "../../components/Admin/AdminLayout";
import Logotipo from '../../assets/png/LogoUser.png';
import Logotipo2 from '../../assets/png/finandax.png';
import { UserMenu } from "../../components/User/UserMenu";
import "./UserLayout.scss";



export function UserLayput(props) {
  const { children } = props;

  return (
<div className="user-layoutU">
      <div className="user-layoutU__leftU">
      <img src={Logotipo} className="logoU" alt="Finandax" />
      <img src={Logotipo2} className="logoU2" alt="Finandax" />
      <UserMenu />
      </div>
      <div className="user-layoutU__rightU">
        <div className="user-layoutU__rightU-headerU">
        <Logout />
        </div>
        {children}
      </div>
    </div>
  );
}

