import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { size } from "lodash";
import { useAuth } from "../../../../hooks";
import { UserItem } from "../UserItem";
import { User } from "../../../../api";

const userController = new User();

export function ListUsers(props) {
  const { usersActive, reload, onReload } = props;
  const [users, setUsers] = useState(null);
  const { accessToken , user : {_id} } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        setUsers(null);
        const response = await userController.getMeUser(_id);
        setUsers(response);
      } catch (error) {
        console.log("Error Cliente controlado ListUsers: ");
        console.error(error);
      }
    })();
  }, [usersActive, reload, accessToken, _id]);



  if (!users) return <Loader active inline="centered" />;
  if (size(users) === 0) return "No hay ningun usuario";

  return (
    <UserItem key={users._id} user={users} onReload={onReload} />
  );
}