import React, { useCallback } from "react";
import { Form, Image } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { Enlaces } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { ENV } from "../../../../utils";
import { initialValues, validationSchema } from "./EnlacesForm.form";
import "./EnlacesForm.scss";

const enlacesController = new Enlaces();

export function EnlacesForm(props) {
  const { onClose, onReload, enlaces } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(enlaces),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!enlaces) {
          await enlacesController.createEnlaces(accessToken, formValue);
        } else {
          await enlacesController.updateEnlaces(
            accessToken,
            enlaces._id,
            formValue
          );
        }
        onReload();
        onClose();
      } catch (error) {
        console.log("Error controlado enlace: ")
        console.error(error);
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    formik.setFieldValue("miniatura", URL.createObjectURL(file));
    formik.setFieldValue("file", file);
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop,
  });

  const getMiniature = () => {
    if (formik.values.file) {
      return formik.values.miniatura;
    } else if (formik.values.miniatura) {
      return `${ENV.BASE_PATH}/${formik.values.miniatura}`;
    }
    return null;
  };

  return (
    <Form className="enlaces-form" onSubmit={formik.handleSubmit}>
      <div className="enlaces-form__miniature" {...getRootProps()}>
        <input {...getInputProps()} />
        {getMiniature() ? (
          <Image size="small" src={getMiniature()} />
        ) : (
          <div>
            <span>Arrastra tu imagen</span>
          </div>
        )}
      </div>

      <Form.Input
        name="titulo"
        placeholder="Nombre del enlace"
        onChange={formik.handleChange}
        value={formik.values.titulo}
        error={formik.errors.titulo}
      />
      <Form.Input
        name="url"
        placeholder="Link del enlace"
        onChange={formik.handleChange}
        value={formik.values.url}
        error={formik.errors.url}
      />

     <Form.Group widths="equal">
        <Form.Dropdown
          placeholder="Seleccióna un rol"
          options={roleOptions}
          selection
          onChange={(_, data) => formik.setFieldValue("id_rol", data.value)}
          value={formik.values.id_rol}
          error={formik.errors.id_rol}
        />
      </Form.Group>

      <Form.TextArea
        name="descripcion"
        placeholder="Pequeña descripción del enlace"
        onChange={formik.handleChange}
        value={formik.values.descripcion}
        error={formik.errors.descripcion}
      />
{
  /*
        <Form.Group widths="equal">
        <Form.Input
          type="number"
          name="precio"
          placeholder="Precio del curso"
          onChange={formik.handleChange}
          value={formik.values.price}
          error={formik.errors.price}
        />
        <Form.Input
          type="number"
          name="score"
          placeholder="Puntuacion del curso"
          onChange={formik.handleChange}
          value={formik.values.score}
          error={formik.errors.score}
        />
      </Form.Group>
  */
}


      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {!enlaces ? "Crear enlace" : "Actualizar enlace"}
      </Form.Button>
    </Form>
  );
}

const roleOptions = [
  {
    key: "user",
    text: "Usuario",
    value: "user",
  },
  {
    key: "admin",
    text: "Administrador",
    value: "admin",
  },
  {
    key: "demo",
    text: "Demo",
    value: "demo",
  },
];