import React from "react";
import { Routes, Route } from "react-router-dom";
import { AdminLayout } from "../layouts";
import { Auth, Users, Menu, Enlaces, Bienvenido } from "../pages/admin";
import { useAuth } from "../hooks";


export function AdminRouter() {
  const { user } = useAuth();
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  return (
    <Routes>
      {!user ? (
     <Route path="/admin/*" element={<Auth/>} />
      ):(
        <>
        {["/admin/"].map((path) => (
          <Route 
          key = {path} 
          path={path} 
          element={loadLayout(AdminLayout, Bienvenido)} 
          />
        ))}

        <Route path="/admin/users" element={loadLayout(AdminLayout, Users)} />
        <Route path="/admin/menu" element={loadLayout(AdminLayout, Menu)} />
        <Route path="/admin/enlaces" element={loadLayout(AdminLayout, Enlaces)} />
        <Route path="/admin/*" element={loadLayout(AdminLayout, Bienvenido)} />
        </>
      )}
    </Routes>
  );
}